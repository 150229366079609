<template>
  <div class="support" data-view>
    <div class="container">
      <div class="support__hero hero hero--center hero--no-bg">
        <h1
          class="hero__title title title--center"
          v-animate-onscroll="'animated backIn'"
        >
          Поддержка
        </h1>
        <div class="hero__text" v-animate-onscroll="'animated backIn delay-05'">
          <p class="p-3">
            Если у&nbsp;вас возникли вопросы или сложности при работе
            с&nbsp;сервисом, вы&nbsp;можете обратиться к&nbsp;нашим менеджерам.
            Мы&nbsp;дадим оперативные ответы и&nbsp;решим возникающие
            затруднения.
          </p>
        </div>
      </div>
    </div>
    <div class="hero__graph hero__graph--support">
      <v-graph
        :scheme="{
          scatter: 0.25,
          angle: 20,
          offset: 0.0,
          background: {
            start: 'rgba(206, 229, 139, 0.16)',
            stop: 'rgba(206, 229, 139, 0)',
          },
          points: {
            count: 8,
            background: 'rgba(235, 248, 223, 0)',
            border: {
              width: 4,
              color: 'rgba(179, 221, 136, 0)',
            },
            radius: 6,
          },
          line: {
            width: 2,
            color: 'rgba(179, 221, 136, 0)',
          },
          animation: {
            duration: 500,
            delay: 2000,
          },
        }"
      />
      <v-graph
        :scheme="{
          scatter: 0.25,
          angle: 15,
          offset: 0.0,
          background: {
            start: 'rgba(118, 200, 120, 0.16)',
            stop: 'rgba(118, 200, 120, 0)',
          },
          points: {
            count: 8,
            background: 'rgba(235, 248, 223, 1)',
            border: {
              width: 4,
              color: 'rgba(179, 221, 136, 1)',
            },
            radius: 6,
          },
          line: {
            width: 2,
            color: 'rgba(179, 221, 136, 1)',
          },
          animation: {
            duration: 500,
            delay: 2000,
          },
        }"
      />
      <v-graph
        :scheme="{
          scatter: 0.25,
          angle: 10,
          offset: 0.0,
          background: {
            start: 'rgba(21, 164, 161, 0.16)',
            stop: 'rgba(21, 164, 161, 0)',
          },
          points: {
            count: 7,
            background: 'rgba(214, 244, 237, 0)',
            border: {
              width: 4,
              color: 'rgba(174, 231, 219, 0)',
            },
            radius: 6,
          },
          line: {
            width: 2,
            color: 'rgba(174, 231, 219, 0)',
          },
          animation: {
            duration: 500,
            delay: 2000,
          },
        }"
      />
    </div>
    <div class="container">
      <div class="support__wrapper">
        <div
          class="support__form"
          v-animate-onscroll="'animated backIn delay-10'"
        >
          <div class="support__form-img">
            <img src="@/assets/img/support.svg" alt="" />
          </div>
          <h2 class="support__form-title">Оставить заявку</h2>
          <el-button type="primary" @click="$modal.show('support')"
            >Написать нам</el-button
          >
        </div>
      </div>
    </div>
    <div class="support__section">
      <div class="container">
        <h2 class="support__subtitle" v-animate-onscroll="'animated backIn'">
          Ответы на&nbsp;часто задаваемые вопросы
        </h2>
        <div class="support__faq">
          <el-row :gutter="48">
            <el-col
              :xs="24"
              :sm="12"
              v-animate-onscroll="'animated backIn delay-05'"
            >
              <v-faq
                title="Какая роль у EdiFin?"
                text="EdiFin выступает как маркетплейс факторинговых услуг и факторинговый брокер. Мы собираем запросы от компаний, желающих получить факторинговое финансирование и передаём данные нашим партнёрам-факторам. Далее вы заключаете соглашение с ними о переуступке долга."
              />
              <v-faq
                title="Взымает ли сервис какие-либо комиссии?"
                text="Вам не нужно платить в адрес EdiFin комиссий и иных платежей. Использование сервиса — бесплатное. Все финансовые условия вы обговариваете напрямую с фактором, без нашего участия."
              />
              <v-faq
                title="Как долго обрабатываются заявки?"
                text="Заявки поступают нашим партнёрам-факторам в течение 5 минут после их отправки. Первичная обратная связь — в течение рабочего дня. С вами свяжутся по телефону или напишут на электронную почту."
              />
              <v-faq
                title="Какие документы требуются для получения финансирования?"
                text="Фактор запросит у вас финансовые документы, договоры поставки и документы для правоспособности."
              />
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              v-animate-onscroll="'animated backIn delay-05'"
            >
              <v-faq
                title="Через какое время мы получим деньги?"
                text="В зависимости от условий сотрудничества с фактором вы можете получать финансирование в течение нескольких часов после осуществления приёмки товара покупателем."
              />
              <v-faq
                title="Можно ли вам доверять?"
                text="Разработчиком и оператором EdiFin выступает компания Ediweb. Мы являемся одним из крупнейших в России операторов ЭДО и интеграторов. На рынке ИТ-услуг работаем с 1999 года. Есть все необходимые лицензии и сертификаты. Мы не передаём ваши данные сторонним лицам и соблюдаем необходимую конфиденциальность."
              />
              <v-faq
                title="Могу ли я договориться об индвидуальных условиях?"
                text="Конечно. Вы можете рассказать фактору особенности ситуации с дебиторской задолженностью в вашей компании и для вас подберут персональное предложение, которое будет учитывать ваши интересы."
              />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <v-dialog name="support">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с&nbsp; вами и&nbsp;поможем решить ваш вопрос с&nbsp;работой
        сервиса.
      </p>
      <v-form
        :action="'https://ediweb.com/subscribe/8421763f'"
        :simple="true"
      />
    </v-dialog>
  </div>
</template>

<script>
const meta = {
  title: "Поддержка",
  description: "Поможем получить финансирова­ние ваших неоплачен­ных счетов",
};

export default {
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "description",
        content: meta.description,
      },
      {
        name: "og:description",
        content: meta.description,
      },
    ],
  },
  components: {
    "v-faq": () => import("@/components/Faq"),
    "v-dialog": () => import("@/components/Dialog"),
    "v-form": () => import("@/components/Form"),
    "v-graph": () => import("@/components/Graph"),
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.support {
  &__wrapper {
    margin: $space-80 0 $space-120;
    display: flex;
    justify-content: center;

    @include mobile {
      margin: $space-0 0 $space-56;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: $space-48 $space-80;
    background-color: $white;
    box-shadow: $shadow-md;
    border-radius: $radius-md;

    @include mobile {
      padding: $space-32 $space-40;
    }

    &-title {
      text-align: center;
      margin: $space-24 0 $space-32 !important;

      @include mobile {
        font-size: 24px;
      }
    }
  }

  &__subtitle {
    text-align: center;

    @include mobile {
      font-size: 24px;
      text-align: left;
    }
  }

  &__section {
    background-color: $gray-light;
    padding: $space-80 0 $space-120;

    @include mobile {
      padding: $space-40 0 $space-56;
    }
  }
}
</style>
